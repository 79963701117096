import Immutable from 'seamless-immutable';

const config = window.weftConfig;
const apiRoot = config.CMS_URL + '/api';

export default (reducer) => {
	// Call the reducer with empty action to populate the initial state
	var initialState = reducer(undefined, {});
	initialState = Immutable.merge(initialState, {
		past: [],
		future: []
	});

	// Return a reducer that handles undo and redo
	return function (state = initialState, action) {
		var outState = state;

		const past = state.past;
		const future = state.future;

		var db_update = false;

		switch(action.type) {
			case 'UNDO':
				if(past.length <= 0) {
					return Immutable({
						...state,
						past: past,
						future: future
					});
				}

				db_update = true;

				const previous = Immutable.merge(state, past[past.length - 1])
				const newPast = past.slice(0, past.length - 1)

				outState = Immutable({
					...previous,
					past: newPast,
					future: [{design: state.design}, ...future]
				});

				break;

			case 'REDO':
				if(future.length <= 0) {
					return Immutable({
						...state,
						past: past,
						future: future
					});
				}

				db_update = true;

				const next = Immutable.merge(state, future[0])
				const newFuture = future.slice(1)

				outState = Immutable({
					...next,
					past: [...past, {design: state.design}],
					future: newFuture
				});

				break;

			default:
				const newState = reducer(state, action)

				if(['UPDATE_MAPPINGS_REQUESTED'].includes(action.type)) {
					return Immutable({
						...newState,
						past: [...past, {design: state.design}],
						future: []
					});
				} else {
					return newState;
				}
		}

		// Update DB if applicable
		if(db_update) {
			const version = outState.design && outState.design.versions ? outState.design.versions.find(v => v.version_id === outState.version_id) : undefined;
			if(!version)
				return outState;

			const mappingEndpoint = `/mappings/${version.mapping.mapping_id}/`;
			fetch(apiRoot + mappingEndpoint, {
	      method: 'PATCH',
	      headers: {
	        "Content-type": "application/json"
	      },
	      body: JSON.stringify({
	      	num_repeats: version.mapping.num_repeats,
	      	weave_map: version.mapping.weave_map,
	      	warp_map: version.mapping.warp_map,
	      	weft_map: version.mapping.weft_map,
	      	warps: version.mapping.warps,
	      	wefts: version.mapping.wefts
	      }),
	      credentials: 'include'
	    });
		}

    return outState;
	}
}
