import * as types from '../actions/actionTypes';

const config = window.weftConfig;

export default (
    state = {
        isFetching: false,
        designs: [],
        availableTags: [],
        tags: [],
        qualities: {},
    },
    action,
) => {
    switch (action.type) {
        // Library designs
        case types.GET_LIBRARY_DESIGNS:
          return {
            ...state,
            isFetching: true,
          };
          break;

        case types.RECEIVE_LIBRARY_DESIGNS:
            return {
              ...state,
              isFetching: false,
              designs: action.data ? action.data : state.designs
            };
          break;

        case types.GET_LIBRARY_DESIGNS_ERROR:
          return {
            ...state,
            isFetching: false,
          };
          break;

        // Design tags
        case types.GET_DESIGN_TAGS:
          return {
            ...state,
            isFetching: true,
          };
          break;

        case types.RECEIVE_DESIGN_TAGS:
            return {
              ...state,
              isFetching: false,
              availableTags: action.data ? action.data : state.availableTags
            };
          break;

        case types.GET_DESIGN_TAGS_ERROR:
          return {
            ...state,
            isFetching: false,
          };
          break;

        case types.RECEIVE_LIBRARY_QUALITIES:
          var qualities = {};
          action.data.forEach(q => {
            qualities[q.quality_id] = q;
          });

          return {
            ...state,
            qualities: qualities,
          };

        case types.TOGGLE_LIBRARY_TAG:
          var newTags = state.tags;

          if(newTags.includes(action.tag)) {
            var tagIndex = newTags.indexOf(action.tag);
            newTags.splice(tagIndex, 1);
          } else {
            newTags.push(action.tag);
          }

          return {
            ...state,
            tags: newTags,
          };
          break;

        case types.SET_LIBRARY_TAG:
          var newTags = state.tags;

          if(!newTags.includes(action.tag)) {
            newTags.push(action.tag);
          }

          return {
            ...state,
            tags: newTags,
          };
          break;

        case types.CLEAR_LIBRARY_TAGS:
          return {
            ...state,
            tags: [],
          };
          break;

        default:
            return state;
    }
};
