import Swagger from 'swagger-client';
import Cookies from 'js-cookie';
import { SubmissionError } from 'redux-form';

import { logoutUser }  from './userActions'

const config = window.weftConfig;

export const getAsyncActionTypes = baseType => ({
  REQUESTED: baseType + '_REQUESTED',
  SUCCEEDED: baseType + '_SUCCEEDED',
  FAILED: baseType + '_FAILED',
});

let apiClient = {};

const getSwaggerClient = swaggerDocUrl => {
  if (!swaggerDocUrl) {
    return Promise.reject(new Error('Swagger doc url must be specified.'));
  }

  // Get token from cookie or local storage
  var token = Cookies.get(config.jwtCookieName);
  if(!token)
    token = window.sessionStorage.getItem('id_token');

  // Use cached client if we've already created it.
  if (apiClient[swaggerDocUrl]) {
    // Ensure that we use a fresh token even for cached client
    var client = apiClient[swaggerDocUrl];
    client.authorizations = {
      jwt: `Bearer ${token}`,
    };

    return Promise.resolve(client);
  }

  return new Swagger({
    url: swaggerDocUrl,
    authorizations: {
      jwt: `Bearer ${token}`,
    },
    requestInterceptor(req) {
      req.headers['Content-Type'] = 'application/json';
      return req
    }
  })
    .then(client => {
      apiClient[swaggerDocUrl] = client;
      return client;
    })
    .catch(err => {
      console.error(
        `Failed to create api client for swagger doc '${swaggerDocUrl}'`,
        err
      );
    });
};

const apiAction = swaggerDocUrl => (baseActionType, callFunc, payload) => {
  const actionType = getAsyncActionTypes(baseActionType);
  return (dispatch, getState) => {
    dispatch({ type: actionType.REQUESTED, payload });
    return getSwaggerClient(swaggerDocUrl).then(api => {
      return callFunc(api.apis)(payload)
        .then(result => {
          dispatch({
            type: actionType.SUCCEEDED,
            error: false,
            payload: result.obj,
          });
          return result.obj;
        })
        .catch(err => {
          dispatch({
            type: actionType.FAILED,
            error: true,
            payload: err.errObj,
          });

          // Log out user if API request fails (JWT invalid or expired)
          if(err.status === 403) {
            console.log("LOGGING OUT");
            dispatch(logoutUser());
          }

          console.error(err.errObj);
          // throw new SubmissionError({ _error: err.errObj.response.text });
        });
    });
  };
};

export default apiAction;
