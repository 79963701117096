import * as types from '../actions/actionTypes';

const config = window.weftConfig;

export default (
  state = {
    colorwayFetching: false,
    productFetching: false,
    fabricFetching: false,
    qualitiesFetching: false,
    imageRotation: 0,
    sort: '',
    filters: {},
    announcementHidden: false,
  },
  action,
) => {
  switch (action.type) {
    case types.RECEIVE_HERO_DATA:
      var keys = Object.keys(action.data);
      var heroData = {
        introduction: {},
        calls_to_action: {},
        last_call: {},
      };
      keys.map(item => {
        if (item.startsWith('introduction')) {
          heroData.introduction[item] = action.data[item];
        } else if (item.startsWith('last_call')) {
          heroData.last_call[item] = action.data[item];
        } else if (
          item.startsWith('first_card') ||
          item.startsWith('second_card')
        ) {
          heroData.calls_to_action[item] = action.data[item];
        }
      });
      return {
        ...state,
        ...heroData,
      };

    case types.GET_FABRIC_DATA:
      return {
        ...state,
        fabricFetching: true,
      };

    case types.RECEIVE_FABRIC_DATA:
      // List
      if (action.data && action.data.constructor === Array) {
        var fabrics = {
          fabrics: action.data,
        };
        return {
          ...state,
          ...fabrics,
          fabricFetching: false,
        };
      } else if (action.data.fabric_id) {
        // Detail
        var fabric = {
          fabric: action.data,
        };
        return {
          ...state,
          ...fabric,
          fabricFetching: false,
        };
      }
      break;

    case types.GET_COLORWAY_DATA:
      return {
        ...state,
        colorwayFetching: true,
      };

    case types.RECEIVE_COLORWAY_DATA:
      // List
      if (action.data && action.data.constructor === Array) {
        var colorways = {
          colorways: action.data,
        };
        return {
          ...state,
          ...colorways,
          colorwayFetching: false,
        };
      } else if (action.data.colorway_id) {
        // Detail
        var colorway = {
          colorway: action.data,
        };
        return {
          ...state,
          ...colorway,
          colorwayFetching: false,
        };
      }
      break;

    case types.GET_PRODUCT_DATA:
      return {
        ...state,
        productFetching: true,
      };

    case types.RECEIVE_PRODUCT_DATA:
      // List
      if (action.data && action.data.constructor === Array) {
        var products = {
          products: action.data,
        };
        return {
          ...state,
          ...products,
          productFetching: false,
        };
      } else if (action.data.product_id) {
        // Detail
        var product = {
          product: action.data,
        };
        return {
          ...state,
          ...product,
          productFetching: false,
        };
      }
      break;


    case types.GET_SHOP_QUALITY_DATA:
      return {
        ...state,
        qualitiesFetching: true,
      };

    case types.RECEIVE_SHOP_QUALITY_DATA:
      return {
        ...state,
        qualitiesFetching: false,
        qualities: action.data,
      };
      break;

    //Shop page
    case types.SHOP_UPDATE_SORT:
      return {
        ...state,
        sort: action.sort_value,
      };

    //Shop detail page
    case types.SHOP_SET_PRODUCT_DETAIL_IMAGE:
      var product = {
        ...state.product,
        image: action.image
      }

      return {
        ...state,
        product: product
      };

    case types.SHOP_SET_DETAIL_IMAGE:
      var colorway = {
        ...state.colorway,
        image: action.image
      }

      return {
        ...state,
        colorway: colorway
      };

    case types.SHOP_SET_SELECTED_COLORWAY:
      return {
        ...state,
        colorway: action.colorway,
      };

    case types.SHOP_ROTATE_FABRIC_IMAGE:
      var rotation = state.imageRotation;

      if(action.ccw)
        rotation -= 90;
      else
        rotation += 90;

      return {
        ...state,
        imageRotation: rotation,
      };

    case types.SHOP_CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      }

    case types.SHOP_SET_FILTER:
      var filters = {
        ...state.filters
      }

      filters[action.filter] = true;

      return {
        ...state,
        filters: filters,
      }

    case types.SHOP_UNSET_FILTER:
      var filters = {
        ...state.filters
      }

      filters[action.filter] = false;

      return {
        ...state,
        filters: filters,
      }

    case types.HIDE_ANNOUNCEMENT:
      return {
        ...state,
        announcementHidden: true,
      }


    default:
      return state;
  }
};
