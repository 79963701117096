import * as types from '../actions/actionTypes';

export default (
  state = {
    collabFetching: false,
  },
  action,
) => {
  switch (action.type) {

    case types.GET_COLLAB_DATA:
    return {
      ...state,
      collabFetching: true,
    };

    case types.RECEIVE_COLLAB_DATA:
      // List
      if (action.data && action.data.constructor === Array) {
        var collabs = {
          collabs: action.data,
        };
        return {
          ...state,
          ...collabs,
          collabFetching: false,
        };
      } else if (action.data.slug) {
        // Detail
        var collab = {
          collab: action.data,
        };
        return {
          ...state,
          ...collab,
          collabFetching: false,
        };
      }
      break;
      
    default:
      return state;
  }
};