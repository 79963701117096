import apiAction from './apiAction';

const config = window.weftConfig;

export function subscribe(email) {
  // Facebook Pixel "lead generation" tracking
  if(window.fbq) {
    window.fbq('track', 'Lead');
  }

  return apiAction(config.webApiSwaggerDoc)(
    'SUBSCRIBE',
    api => api.subscriptions.post1,
    { email: email }
  );
}
