/*
 *   UI actions
 */
export const UNDO = 'UNDO';
export const REDO = 'REDO';

export const RESET_CREATE = 'RESET_CREATE';
export const SET_MODE = 'SET_MODE';
export const SET_STATUS = 'SET_STATUS';
export const SET_SCREEN = 'SET_SCREEN';
export const MENU_SET_SCREEN = 'MENU_SET_SCREEN';
export const MENU_SET_DESIGN_LIST = 'MENU_SET_DESIGN_LIST';
export const SET_VERSION = 'SET_VERSION';
export const SET_MODAL = 'SET_MODAL';
export const SET_DEMO_MODAL = 'SET_DEMO_MODAL';
export const RESET_MENU_AREA = 'RESET_MENU_AREA';
export const SET_MORE_COLORS = 'SET_MORE_COLORS'

// Design menu
export const SET_SELECTED_DESIGN = 'SET_SELECTED_DESIGN';

export const DELETE_SELECTED_DESIGN = 'DELETE_SELECTED_DESIGN';
export const DELETE_SELECTED_DESIGN_SUCCEEDED = 'DELETE_SELECTED_DESIGN_SUCCEEDED';
export const DELETE_SELECTED_DESIGN_FAILED = 'DELETE_SELECTED_DESIGN_FAILED';

// Upload control
export const SET_UPLOAD_STEP = 'SET_UPLOAD_STEP';
export const SET_USER_IMAGE_URL = 'SET_USER_IMAGE_URL';
export const SET_VALIDATED_IMAGE_URL = 'SET_VALIDATED_IMAGE_URL';
export const SET_SELECTED_UPLOAD_QUALITY = 'SET_SELECTED_UPLOAD_QUALITY';
export const SET_UPLOAD_DESIGN_INFO = 'SET_UPLOAD_DESIGN_INFO';

export const SET_MANUAL_CANVAS_PARAMS = 'SET_MANUAL_CANVAS_PARAMS';

export const ZOOM = 'ZOOM';
export const ZOOM_IN = 'ZOOM_IN';
export const ZOOM_OUT = 'ZOOM_OUT';

export const PAN_START = 'PAN_START';
export const PAN_END = 'PAN_END';

export const PINCH_START = 'PINCH_START';
export const PINCH_END = 'PINCH_END';

export const TOGGLE_TOOL_AREA = 'TOGGLE_TOOL_AREA';

export const SET_SELECTED_CONTROL = 'SET_SELECTED_CONTROL';
export const SET_SELECTED_DROPDOWN = 'SET_SELECTED_DROPDOWN';
export const SET_SELECTED_QUALITY = 'SET_SELECTED_QUALITY';
export const SET_SELECTED_LAYER = 'SET_SELECTED_LAYER';
export const SET_SELECTED_WARP = 'SET_SELECTED_WARP';
export const SET_SELECTED_WEFT = 'SET_SELECTED_WEFT';
export const SET_SELECTED_WEAVE_GROUP = 'SET_SELECTED_WEAVE_GROUP';

export const SET_SELECTED_REPEATS = 'SET_SELECTED_REPEATS';
export const SET_RANDOMIZE_MODE = 'SET_RANDOMIZE_MODE';
export const SET_SELECTED_3D_MODEL = 'SET_SELECTED_3D_MODEL';
export const TOGGLE_RAILROAD_3D = 'TOGGLE_RAILROAD_3D';
export const TOGGLE_RULERS = 'TOGGLE_RULERS';

/*
 *   DB update actions
 */
export const UPDATE_MAPPINGS_REQUESTED = 'UPDATE_MAPPINGS_REQUESTED';
export const UPDATE_MAPPINGS_SUCCEEDED = 'UPDATE_MAPPINGS_SUCCEEDED';
export const UPDATE_MAPPINGS_FAILED = 'UPDATE_MAPPINGS_FAILED';

/*
 *   GLDesignCanvas actions
 */
export const RENDERER_UPDATE_STATE = 'RENDERER_UPDATE_STATE';

/*
 *   Fetch CMS content
 */
export const GET_QUALITY_DATA = 'GET_QUALITY_DATA';
export const GET_QUALITY_DATA_ERROR = 'GET_QUALITY_DATA_ERROR';
export const RECEIVE_QUALITY_DATA = 'RECEIVE_QUALITY_DATA';

export const GET_YARN_DATA = 'GET_YARN_DATA';
export const GET_YARN_DATA_ERROR = 'GET_YARN_DATA_ERROR';
export const RECEIVE_YARN_DATA = 'RECEIVE_YARN_DATA';

export const GET_WEAVE_GROUP_DATA = 'GET_WEAVE_GROUP_DATA';
export const GET_WEAVE_GROUP_DATA_ERROR = 'GET_WEAVE_GROUP_DATA_ERROR';
export const RECEIVE_WEAVE_GROUP_DATA = 'RECEIVE_WEAVE_GROUP_DATA';

export const GET_DESIGNS_DATA = 'GET_DESIGNS_DATA';
export const GET_DESIGNS_DATA_ERROR = 'GET_DESIGNS_DATA_ERROR';
export const RECEIVE_DESIGNS_DATA = 'RECEIVE_DESIGNS_DATA';

export const SET_DESIGN = 'SET_DESIGN';
export const SET_DESIGN_RECEIVE_DATA = 'SET_DESIGN_RECEIVE_DATA';
export const SET_DESIGN_ERROR = 'SET_DESIGN_ERROR';

export const GET_SURFACE_IMAGE = 'GET_SURFACE_IMAGE';
export const RECEIVE_SURFACE_IMAGE = 'RECEIVE_SURFACE_IMAGE';
export const GET_SURFACE_IMAGE_ERROR = 'GET_SURFACE_IMAGE_ERROR';

export const GET_MAPPINGS = 'GET_MAPPINGS';
export const RECEIVE_MAPPINGS = 'RECEIVE_MAPPINGS';
export const GET_MAPPINGS_ERROR = 'GET_MAPPINGS_ERROR';

/*
 *   Fetch renderer content
 */
export const GET_CLOTH_TILE = 'GET_CLOTH_TILE';
export const GET_CLOTH_TILE_ERROR = 'GET_CLOTH_TILE_ERROR';
export const RECEIVE_CLOTH_TILE = 'RECEIVE_CLOTH_TILE';

/*
 *   Image upload
 */
export const IMAGE_UPLOAD_REQUESTED = 'IMAGE_UPLOAD_REQUESTED';
export const IMAGE_UPLOAD_SUCCEEDED = 'IMAGE_UPLOAD_SUCCEEDED';
export const IMAGE_UPLOAD_FAILED = 'IMAGE_UPLOAD_FAILED';

export const SET_IMAGE_UPLOAD_ERROR = 'SET_IMAGE_UPLOAD_ERROR';

/*
 *   Worker tasks
 */
export const IMAGE_VALIDATE_REQUESTED = 'IMAGE_VALIDATE_REQUESTED';
export const IMAGE_VALIDATE_SUCCEEDED = 'IMAGE_VALIDATE_SUCCEEDED';
export const IMAGE_VALIDATE_FAILED = 'IMAGE_VALIDATE_FAILED';

export const CREATE_DESIGN_REQUESTED = 'CREATE_DESIGN_REQUESTED';
export const CREATE_DESIGN_SUCCEEDED = 'CREATE_DESIGN_SUCCEEDED';
export const CREATE_DESIGN_FAILED = 'CREATE_DESIGN_FAILED';

export const COPY_TEMPLATE_REQUESTED = 'COPY_TEMPLATE_REQUESTED';
export const COPY_TEMPLATE_SUCCEEDED = 'COPY_TEMPLATE_SUCCEEDED';
export const COPY_TEMPLATE_FAILED = 'COPY_TEMPLATE_FAILED';

export const CREATE_DESIGN_SNAPSHOT_REQUESTED = 'CREATE_DESIGN_SNAPSHOT_REQUESTED';
export const CREATE_DESIGN_SNAPSHOT_SUCCEEDED = 'CREATE_DESIGN_SNAPSHOT_SUCCEEDED';
export const CREATE_DESIGN_SNAPSHOT_FAILED = 'CREATE_DESIGN_SNAPSHOT_FAILED';

export const CHANGE_QUALITY_REQUESTED = 'CHANGE_QUALITY_REQUESTED';
export const CHANGE_QUALITY_SUCCEEDED = 'CHANGE_QUALITY_SUCCEEDED';
export const CHANGE_QUALITY_FAILED = 'CHANGE_QUALITY_FAILED';
