import * as types from '../actions/actionTypes';

const config = window.weftConfig;

export default (
    state = {
        isFetching: false,
        status: {},
        orders: []
    },
    action,
) => {
    switch (action.type) {
        case types.GET_SYSTEM_STATUS_DATA:
          return {
            ...state,
            isFetching: true,
          };
          break;

        case types.GET_SYSTEM_STATUS_DATA_ERROR:
          return {
            ...state,
            isFetching: false,
          };
          break;

        case types.RECEIVE_SYSTEM_STATUS_DATA:
            return {
              ...state,
              status: action.data ? action.data : state.status
            };
          break;

        case types.GET_ORDER_SUMMARY_DATA:
          return {
            ...state,
            isFetching: true,
          };
          break;

        case types.GET_ORDER_SUMMARY_DATA_ERROR:
          return {
            ...state,
            isFetching: false,
          };
          break;

        case types.RECEIVE_ORDER_SUMMARY_DATA:
            return {
              ...state,
              orders: action.data ? action.data : state.orders
            };
          break;

        default:
            return state;
    }
};
