import { lazy } from 'react';

// Dynamic import of components to enable code splitting
export const IndexPage = lazy(() => import('./components/IndexPage/IndexPage' /* webpackChunkName: "index" */));
export const AboutPage = lazy(() => import('./components/AboutPage/AboutPage' /* webpackChunkName: "about" */));
export const SustainabilityPage = lazy(() => import('./components/SustainabilityPage/SustainabilityPage' /* webpackChunkName: "sustainability" */));
// export const HowPage = lazy(() => import('./components/HowPage/HowPage' /* webpackChunkName: "how" */));
export const CollabsPage = lazy(() => import('./components/CollabsPage' /* webpackChunkName: "collabs" */));
export const CollabArtistPage = lazy(() => import('./components/CollabArtistPage' /* webpackChunkName: "collab_artist" */));
export const ShopPage = lazy(() => import('./components/ShopPages/ShopPage' /* webpackChunkName: "shop" */));
export const SymmetryPage = lazy(() => import('./components/ShopPages/SymmetryPage' /* webpackChunkName: "symmetry" */));
export const FabricDetailPage = lazy(() => import('./components/ShopPages/FabricDetailPage' /* webpackChunkName: "fabric_detail" */));
export const ProductDetailPage = lazy(() => import('./components/ShopPages/ProductDetailPage' /* webpackChunkName: "shop_detail" */));
export const QualitiesPage = lazy(() => import('./components/QualitiesPage' /* webpackChunkName: "qualities" */));
export const RemixPage = lazy(() => import('./components/LibraryPages/RemixPage' /* webpackChunkName: "remix" */));
export const FeaturedFabricsPage = lazy(() => import('./components/LibraryPages/FeaturedFabricsPage' /* webpackChunkName: "featuredfabrics" */));
export const AccountPage = lazy(() => import('./components/Account/AccountPage' /* webpackChunkName: "account" */));
export const OrdersPage = lazy(() => import('./components/Account/OrdersPage' /* webpackChunkName: "orders" */));
export const SubscriptionsPage = lazy(() => import('./components/Account/SubscriptionsPage' /* webpackChunkName: "subscriptions" */));

export const CreatePage = lazy(() => import('./components/CreatePage/CreatePage' /* webpackChunkName: "create" */));

export const WeftProPage = lazy(() => import('./components/WeftProPage/WeftProPage' /* webpackChunkName: "weft_pro" */));

export const FAQPage = lazy(() => import('./components/ContentPages/FAQPage' /* webpackChunkName: "faq" */));
export const ContactPage = lazy(() => import('./components/ContentPages/ContactPage' /* webpackChunkName: "contact" */));
export const PrivacyPage = lazy(() => import('./components/ContentPages/PrivacyPage' /* webpackChunkName: "privacy" */));
export const TermsPage = lazy(() => import('./components/ContentPages/TermsPage' /* webpackChunkName: "terms" */));
export const ShippingReturnsPage = lazy(() => import('./components/ContentPages/ShippingReturnsPage' /* webpackChunkName: "shipping_returns" */));
export const B2BPage = lazy(() => import('./components/B2BPage/B2BPage' /* webpackChunkName: "b2b" */));

export const TutorialsPage_Weaving = lazy(() => import('./components/TutorialsPages/TutorialsPage_Weaving' /* webpackChunkName: "tutorials_weaving" */));
export const TutorialsPage_GetStarted = lazy(() => import('./components/TutorialsPages/TutorialsPage_GetStarted' /* webpackChunkName: "tutorials_get_started" */));
export const TutorialsPage_Clean = lazy(() => import('./components/TutorialsPages/TutorialsPage_Clean' /* webpackChunkName: "tutorials_clean" */));
export const TutorialsPage_Index = lazy(() => import('./components/TutorialsPages/TutorialsPage_Index' /* webpackChunkName: "tutorials_index" */));
export const TutorialsPage_Repeat = lazy(() => import('./components/TutorialsPages/TutorialsPage_Repeat' /* webpackChunkName: "tutorials_repeat" */));

export const LandingPageContainer = lazy(() => import('./containers/LandingPageContainer' /* webpackChunkName: "landing_container" */));
export const SignInPage = lazy(() => import('./components/SignInPage/SignInPage' /* webpackChunkName: "sign_in" */));
export const SignUpPage = lazy(() => import('./components/SignUpPage/SignUpPage' /* webpackChunkName: "sign_up" */));
export const ForgotPasswordPage = lazy(() => import('./components/ForgotPasswordPages/ForgotPasswordPage' /* webpackChunkName: "forgot_password" */));
export const ResetPasswordPage = lazy(() => import('./components/ForgotPasswordPages/ResetPasswordPage' /* webpackChunkName: "reset_password" */));

export const StatusPage = lazy(() => import('./components/StatusPage/StatusPage' /* webpackChunkName: "status" */));

export const NotFoundPageEmbed = lazy(() => import('./components/ErrorPages/NotFoundPageEmbed' /* webpackChunkName: "not_found_embed" */));
export const NotFoundPage = lazy(() => import('./components/ErrorPages/NotFoundPage' /* webpackChunkName: "not_found" */));
