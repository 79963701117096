import React, { useEffect, useState, Suspense } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Helmet } from 'react-helmet';

import * as userActions from './actions/userActions';
import { history } from './store';
import * as Routes from './Routes';


const App = (props) => {
  const [weftConfig, setWeftConfig] = useState();

  // Wait for env
  useEffect(() => {
    setWeftConfig(window.weftConfig);
  }, [window.weftConfig]);

  if (!weftConfig) {
    return <div />
  }

  return (
    <ConnectedRouter history={history}>
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </Helmet>

        <Suspense fallback={<div></div>}>
          <Switch>
            <Redirect from="/beta" to="/" />
            <Redirect from="/digital-loom" to="/create" />
            <Route exact path="/" component={Routes.IndexPage} />
            <Route path="/sign-in" component={Routes.SignInPage} />
            <Route path="/sign-up/:special?" component={Routes.SignUpPage} />
            <Route path="/forgot-password" component={Routes.ForgotPasswordPage} />
            <Route path="/reset-password" component={Routes.ResetPasswordPage} />
            <Route path="/about" component={Routes.AboutPage} />
            <Route path="/how" component={Routes.AboutPage} />
            <Route path="/sustainability" component={Routes.SustainabilityPage} />
            <Route path="/collabs" component={Routes.CollabsPage} />
            <Route path="/collab-artist/:slug" component={Routes.CollabArtistPage} />
            <Route path="/shop/fabric/:id/:colorway?" component={Routes.FabricDetailPage} />
            <Route path="/shop/product/:id" component={Routes.ProductDetailPage} />
            <Route path="/shop" component={Routes.ShopPage} />
            <Route path="/symmetry" component={Routes.SymmetryPage} />
            <Route path="/library" component={Routes.RemixPage} />
            <Route path="/remix" component={Routes.RemixPage} />
            <Route path="/featured" component={Routes.FeaturedFabricsPage} />
            <Route path="/qualities" component={Routes.QualitiesPage} />
            <Route path="/account" component={Routes.AccountPage} />
            <Route path="/orders" component={Routes.OrdersPage} />
            <Route path="/subscriptions" component={Routes.SubscriptionsPage} />
            <Route path="/create/:sku?" component={Routes.CreatePage} />
            <Route path="/embed/:sku?" render={(props) => <Routes.CreatePage {...props} appMode />} />

            {/* Static text pages */}
            <Route path="/faq" component={Routes.FAQPage} />
            <Route path="/contact" component={Routes.ContactPage} />
            <Route path="/privacy" component={Routes.PrivacyPage} />
            <Route path="/terms" component={Routes.TermsPage} />
            <Route path="/shipping" component={Routes.ShippingReturnsPage} />
            <Route path="/b2b" component={Routes.B2BPage} />

            <Route path="/weft-pro" component={Routes.WeftProPage} />

            <Route exact path="/tutorials" component={Routes.TutorialsPage_Weaving} />
            <Route path="/tutorials/getstarted" component={Routes.TutorialsPage_GetStarted} />
            <Route path="/tutorials/repeat" component={Routes.TutorialsPage_Repeat} />
            <Route path="/tutorials/clean" component={Routes.TutorialsPage_Clean} />
            <Route path="/tutorials/index" component={Routes.TutorialsPage_Index} />

            {/* Internal pages */}
            <Route path="/status" component={Routes.StatusPage} />

            {/* 404 */}
            <Route path="/e/404" component={Routes.NotFoundPageEmbed} />
            <Route component={Routes.NotFoundPage} />
          </Switch>
        </Suspense>
      </>
    </ConnectedRouter>
  );
};

function mapStateToProps(state) {
  return { isAuthenticated: state.user.isAuthenticated };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
