export const JWT_AUTH_SUCCEEDED = 'JWT_AUTH_SUCCEEDED';
export const JWT_AUTH_FAILED = 'JWT_AUTH_FAILED';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const SUBSCRIBE_REQUESTED = 'SUBSCRIBE_REQUESTED';
export const SUBSCRIBE_SUCCEEDED = 'SUBSCRIBE_SUCCEEDED';
export const SUBSCRIBE_FAILED = 'SUBSCRIBE_FAILED';

export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR';
export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';

export const GET_ORDERS = 'GET_ORDERS';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';

export const UPDATE_YARDAGE = 'UPDATE_YARDAGE';
export const TOGGLE_MINI_CART = 'TOGGLE_MINI_CART';
export const REDIRECT_TO_CHECKOUT = 'REDIRECT_TO_CHECKOUT';
export const APPLY_COUPON_CODE = 'APPLY_COUPON_CODE';

export const GET_DESIGNS_FOR_USER = 'GET_DESIGNS_FOR_USER';
export const GET_DESIGNS_FOR_USER_RES = 'GET_DESIGNS_FOR_USER_RES';
export const GET_QUALITIES = 'GET_QUALITIES_REQ';
export const GET_QUALITIES_RES = 'GET_QUALITIES_RES';
export const GET_YARNS_FOR_QUALITY = 'GET_YARNS_FOR_QUALITY';
export const GET_YARNS_FOR_QUALITY_RES = 'GET_YARNS_FOR_QUALITY_RES';
export const GET_WEAVE_GROUPS_FOR_QUALITY = 'GET_WEAVE_GROUPS_FOR_QUALITY';
export const GET_WEAVE_GROUPS_FOR_QUALITY_RES = 'GET_WEAVE_GROUPS_FOR_QUALITY_RES';
export const CREATE_RANDOM_MAPPING = 'CREATE_RANDOM_MAPPING';
export const CANVAS_ZOOM_IN = 'CANVAS_ZOOM_IN';
export const CANVAS_ZOOM_OUT = 'CANVAS_ZOOM_OUT';
export const CANVAS_PAN_START = 'CANVAS_PAN_START';
export const CANVAS_PAN_END = 'CANVAS_PAN_END';
export const PAN_TOGGLE = 'PAN_TOGGLE';
export const DESIGN_UNDO = 'DESIGN_UNDO';
export const DESIGN_REDO = 'DESIGN_REDO';
export const DESIGN_SAVE_HISTORY = 'DESIGN_SAVE_HISTORY';
export const DESIGN_EMPTY_HISTORY = 'DESIGN_EMPTY_HISTORY';
export const SET_SELECTED_LAYER = 'SET_SELECTED_LAYER';
export const SET_SELECTED_WARP = 'SET_SELECTED_WARP';
export const SET_SELECTED_WEFT = 'SET_SELECTED_WEFT';
export const SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN';
export const SET_SELECTED_DESIGN = 'SET_SELECTED_DESIGN';
// export const RENDERER_STATE_CHANGED = 'RENDERER_STATE_CHANGED';
export const UPDATE_VERSION_MAPPINGS = 'UPDATE_VERSION_MAPPINGS';
export const UPDATE_VERSION_MAPPINGS_RES = 'UPDATE_VERSION_MAPPINGS_RES';
export const CHANGE_DESIGN_QUALITY_REQ = 'CHANGE_DESIGN_QUALITY_REQ';
export const CHANGE_DESIGN_QUALITY_RES = 'CHANGE_DESIGN_QUALITY_RES';
export const RELOAD_DESIGN_INFO = 'RELOAD_DESIGN_INFO';

export const GET_HERO_DATA = 'GET_HERO_DATA';
export const GET_HERO_DATA_ERROR = 'GET_HERO_DATA_ERROR';
export const RECEIVE_HERO_DATA = 'RECEIVE_HERO_DATA';

export const GET_FABRIC_DATA = 'GET_FABRIC_DATA';
export const RECEIVE_FABRIC_DATA = 'RECEIVE_FABRIC_DATA';
export const GET_FABRIC_DATA_ERROR = 'GET_FABRIC_DATA_ERROR';

export const GET_COLORWAY_DATA = 'GET_COLORWAY_DATA';
export const RECEIVE_COLORWAY_DATA = 'RECEIVE_COLORWAY_DATA';
export const GET_COLORWAY_DATA_ERROR = 'GET_COLORWAY_DATA_ERROR';

export const GET_SYMMETRY_FABRIC_DATA = 'GET_SYMMETRY_FABRIC_DATA';
export const RECEIVE_SYMMETRY_FABRIC_DATA = 'RECEIVE_SYMMETRY_FABRIC_DATA';
export const GET_SYMMETRY_FABRIC_DATA_ERROR = 'GET_SYMMETRY_FABRIC_DATA_ERROR';

export const GET_SYMMETRY_COLORWAY_DATA = 'GET_CSYMMETRY_OLORWAY_DATA';
export const RECEIVE_SYMMETRY_COLORWAY_DATA = 'RECEIVE_SYMMETRY_COLORWAY_DATA';
export const GET_SYMMETRY_COLORWAY_DATA_ERROR = 'GET_SYMMETRY_COLORWAY_DATA_ERROR';

export const GET_PRODUCT_DATA = 'GET_PRODUCT_DATA';
export const RECEIVE_PRODUCT_DATA = 'RECEIVE_PRODUCT_DATA';
export const GET_PRODUCT_DATA_ERROR = 'GET_PRODUCT_DATA_ERROR';

export const GET_SHOP_QUALITY_DATA = 'GET_SHOP_QUALITY_DATA';
export const RECEIVE_SHOP_QUALITY_DATA = 'RECEIVE_SHOP_QUALITY_DATA';
export const GET_SHOP_QUALITY_DATA_ERROR = 'GET_SHOP_QUALITY_DATA_ERROR';

export const GET_COLLAB_DATA = 'GET_COLLAB_DATA';
export const RECEIVE_COLLAB_DATA = 'RECEIVE_COLLAB_DATA';
export const GET_COLLAB_DATA_ERROR = 'GET_COLLAB_DATA_ERROR';

export const SHOP_UPDATE_SORT = 'SHOP_UPDATE_SORT';
export const SHOP_SET_PRODUCT_DETAIL_IMAGE = 'SHOP_SET_PRODUCT_DETAIL_IMAGE';
export const SHOP_SET_DETAIL_IMAGE = 'SHOP_SET_DETAIL_IMAGE';
export const SHOP_SET_SELECTED_COLORWAY = 'SHOP_SET_SELECTED_COLORWAY';
export const SHOP_ROTATE_FABRIC_IMAGE = 'SHOP_ROTATE_FABRIC_IMAGE';

export const SHOP_CLEAR_FILTERS = 'SHOP_CLEAR_FILTERS';
export const SHOP_SET_FILTER = 'SHOP_SET_FILTER';
export const SHOP_UNSET_FILTER = 'SHOP_UNSET_FILTER';

export const GET_LIBRARY_DESIGNS = 'GET_LIBRARY_DESIGNS';
export const RECEIVE_LIBRARY_DESIGNS = 'RECEIVE_LIBRARY_DESIGNS';
export const GET_LIBRARY_DESIGNS_ERROR = 'GET_LIBRARY_DESIGNS_ERROR';

export const GET_DESIGN_TAGS = 'GET_DESIGN_TAGS';
export const RECEIVE_DESIGN_TAGS = 'RECEIVE_DESIGN_TAGS';
export const GET_DESIGN_TAGS_ERROR = 'GET_DESIGN_TAGS_ERROR';

export const GET_LIBRARY_QUALITIES = 'GET_LIBRARY_QUALITIES';
export const RECEIVE_LIBRARY_QUALITIES = 'RECEIVE_LIBRARY_QUALITIES';
export const GET_LIBRARY_QUALITIES_ERROR = 'GET_LIBRARY_QUALITIES_ERROR';

export const TOGGLE_LIBRARY_TAG = 'TOGGLE_LIBRARY_TAG';
export const SET_LIBRARY_TAG = 'SET_LIBRARY_TAG';
export const CLEAR_LIBRARY_TAGS = 'CLEAR_LIBRARY_TAGS';

export const HMAC_ENCODE_REQUESTED = 'HMAC_ENCODE_REQUESTED';
export const HMAC_ENCODE_SUCCEEDED = 'HMAC_ENCODE_SUCCEEDED';
export const HMAC_ENCODE_FAILED = 'HMAC_ENCODE_FAILED';

export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_RESPONSE = 'ADD_TO_CART_RESPONSE';
export const ADD_TO_CART_SET_LOADING = 'ADD_TO_CART_SET_LOADING';
export const ADD_TO_CART_SET_SUCCESS = 'ADD_TO_CART_SET_SUCCESS';
export const ADD_TO_CART_HIDE_NOTIFICATION = 'ADD_TO_CART_HIDE_NOTIFICATION';
export const UPDATE_CART = 'UPDATE_CART';

export const HIDE_ANNOUNCEMENT = 'HIDE_ANNOUNCEMENT';

export const UPDATE_ACCOUNT_INFO_REQUEST = 'UPDATE_ACCOUNT_INFO_REQUEST';
export const UPDATE_ACCOUNT_INFO_SUCCESS = 'UPDATE_ACCOUNT_INFO_SUCCESS';
export const UPDATE_ACCOUNT_INFO_ERROR = 'UPDATE_ACCOUNT_INFO_ERROR';

export const SHOW_ACCOUNT_INFO_FORM = 'SHOW_ACCOUNT_INFO_FORM';
export const HIDE_ACCOUNT_INFO_FORM = 'HIDE_ACCOUNT_INFO_FORM';

export const SHOW_USER_DROPDOWN = 'SHOW_USER_DROPDOWN';
export const HIDE_USER_DROPDOWN = 'HIDE_USER_DROPDOWN';
export const TOGGLE_USER_DROPDOWN = 'TOGGLE_USER_DROPDOWN';

export const SHOW_NAVBAR_DROPDOWN = 'SHOW_NAVBAR_DROPDOWN';
export const HIDE_NAVBAR_DROPDOWN = 'HIDE_NAVBAR_DROPDOWN';
export const TOGGLE_NAVBAR_DROPDOWN = 'TOGGLE_NAVBAR_DROPDOWN';

export const GET_ORDER_SUMMARY_DATA = 'GET_ORDER_SUMMARY_DATA';
export const RECEIVE_ORDER_SUMMARY_DATA = 'RECEIVE_ORDER_SUMMARY_DATA';
export const GET_ORDER_SUMMARY_DATA_ERROR = 'GET_ORDER_SUMMARY_DATA_ERROR';

export const GET_SYSTEM_STATUS_DATA = 'GET_SYSTEM_STATUS_DATA';
export const RECEIVE_SYSTEM_STATUS_DATA = 'RECEIVE_SYSTEM_STATUS_DATA';
export const GET_SYSTEM_STATUS_DATA_ERROR = 'GET_SYSTEM_STATUS_DATA_ERROR';
