import { logoutUser } from './userActions';

var config = window.weftConfig;
const apiRoot = config.CMS_URL + '/api';

// Generic reducer for fetching content
export function fetchContent(endpoint, getAction, receiveAction, errorAction, requiresAuth=true) {
  return (dispatch, getState) => {
    dispatch(getAction());

    // Build fetch headers
    var fetchInit = {
      credentials: 'include'
    }

    if(requiresAuth) {
      
      if(window.sessionStorage.getItem('id_token')) {
        fetchInit.headers = {
          'Authorization': 'Bearer ' + window.sessionStorage.getItem('id_token')
        };
      }
    }

    return fetch(apiRoot + endpoint, fetchInit)
      .then(response => {
        if (response.status === 200)
          return response.json().then(data => dispatch(receiveAction(data)));
        else if (response.status === 401) {
          dispatch(errorAction({ status: response.status }));
          return dispatch(logoutUser());
        } else {
          return response.json().then(data => {
            dispatch(errorAction({ status: response.status, response: data }));
            throw new Error(`error fetching ${endpoint} ${response.status} ${data}`);
          });
        }
      })
      .catch(err => { throw err; });
  };
}
