/* eslint no-redeclare: "off" */
import Immutable from 'seamless-immutable';

import * as types from '../actions/create/createActionTypes';

// Limits for state.canvas.fov and state.canvas.zoomLevel
const ZOOM_MIN_FOV = 3.0;
const ZOOM_MAX_FOV = 220.0;
const ZOOM_LEVELS = 7;

function clampFOV(fov) {
  if(fov < ZOOM_MIN_FOV)
    fov = ZOOM_MIN_FOV;
  else if(fov > ZOOM_MAX_FOV)
    fov = ZOOM_MAX_FOV;

  return fov;
}

function quantizeZoomLevel(fov) {
  return Math.round(Math.sqrt((fov - ZOOM_MIN_FOV) / (ZOOM_MAX_FOV - ZOOM_MIN_FOV)) / (1.0 / (ZOOM_LEVELS - 1)));
}

function fovStep(zoomLevel) {
  return Math.pow(zoomLevel / (ZOOM_LEVELS - 1), 2) * (ZOOM_MAX_FOV - ZOOM_MIN_FOV) + ZOOM_MIN_FOV;
}

// Initial state
const initialState = Immutable({
  mode: "DEMO",
  screen: "MENU",
  status: "LOADING",
  modal: "",

  hideToolArea: false,

  qualities: {},
  design: {},
  version_id: 0,

  canvas: {
    visible: true,
    selectedSegId: -2,
    zoomLevel: 4,            // which step on the zoom control is selected
    fov: 75.0,               // horizontal width of visible cloth area in 2D mode, inches
    trans: [0, 0],           // center of visible cloth area in 2D mode, negated
    panStartUV: [0, 0],
    panInProgress: false,
    didDrag: false,
    rendererState: "STOPPED",
  },

  menu: {
    state: "DESIGN_MENU",
    status: "READY",
    errors: [],
    warnings: [],

    // Design browser
    design: {
      designList: 'DEMO',
      selectedDesign: {
        design_id: -2
      },
    },

    // Upload control
    upload: {
      step: 0,
      moreColors: false,
    }
  },

  selectedControl: 'YARNS',
  selectedDropdown: '',

  selectedRepeat: '',
  randomizeMode: 3,
  selected3dModel: -1,
  railroad3d: false,

  showRulers: false,

  selectedLayer: -2,
  selectedWarp: -1,
  selectedWeft: -1,
  selectedQuality: -1,
  selectedWeaveGroup: 0,
});

export default (
  state = initialState,
  action,
) => {
  switch (action.type) {

    /*
     *   UI actions
     */
    case types.RESET_CREATE:
      return Immutable.merge(state, initialState, {deep: true});

    case types.SET_MODE:
      return Immutable.set(state, 'mode', action.mode);
      // return Immutable({
      //   ...state,
      //   mode: action.mode
      // });

    case types.SET_STATUS:
      return Immutable.set(state, 'status', action.status);
      // return Immutable({
      //   ...state,
      //   status: action.status
      // });

    case types.SET_SCREEN:
      return Immutable.set(state, 'screen', action.screen);
      // return Immutable({
      //   ...state,
      //   screen: action.screen
      // });

    case types.MENU_SET_SCREEN:
      return Immutable.setIn(state, ['menu', 'state'], action.screen);
      // var menu = {
      //   ...state.menu,
      //   state: action.screen
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.MENU_SET_DESIGN_LIST:
      return Immutable.setIn(state, ['menu', 'design', 'designList'], action.list);
      // var menu = {
      //   ...state.menu,
      //   design: {
      //     ...state.menu.design,
      //     designList: action.list
      //   }
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.SET_VERSION:
      return Immutable.set(state, 'version_id', action.version_id);
      // return {
      //   ...state,
      //   version_id: action.version_id
      // };

    case types.SET_MODAL:
      return Immutable.set(state, 'modal', action.modal);
      // return {
      //   ...state,
      //   modal: action.modal
      // };

    case types.SET_DEMO_MODAL:
      return Immutable.set(state, 'demoModal', action.visible);
      // return {
      //   ...state,
      //   demoModal: action.visible
      // };

    case types.RESET_MENU_AREA:
      var menu = {
        state: "DESIGN_MENU",
        status: "READY",
        upload: {
          step: 0,
        }
      };

      return Immutable.merge(state, {menu: menu}, {deep: true});

      // var menu = {
      //   ...state.menu,
      //   state: "DESIGN_MENU",
      //   status: "READY",
      //   upload: {
      //     ...state.menu.upload,
      //     step: 0,
      //   }
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.SET_MORE_COLORS:
      return Immutable.setIn(state, ['menu', 'upload', 'moreColors'], action.more_colors);

    case types.SET_UPLOAD_STEP:
      var menu = {
        status: "READY",
        upload: {
          step: action.step
        }
      };

      return Immutable.merge(state, {menu: menu}, {deep: true});

      // var menu = {
      //   ...state.menu,
      //   status: "READY",
      //   upload: {
      //     ...state.menu.upload,
      //     step: action.step
      //   }
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.SET_USER_IMAGE_URL:
      return Immutable.setIn(state, ['menu', 'upload', 'url'], action.url);

      // var menu = {
      //   ...state.menu,
      //   upload: {
      //     ...state.menu.upload,
      //     url: action.url
      //   }
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.SET_VALIDATED_IMAGE_URL:
      return Immutable.setIn(state, ['menu', 'upload', 'validatedUrl'], action.url);

      // var menu = {
      //   ...state.menu,
      //   upload: {
      //     ...state.menu.upload,
      //     validatedUrl: action.url
      //   }
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.SET_SELECTED_UPLOAD_QUALITY:
      return Immutable.setIn(state, ['menu', 'upload', 'selectedQuality'], action.quality);

      // var menu = {
      //   ...state.menu,
      //   upload: {
      //     ...state.menu.upload,
      //     selectedQuality: action.quality
      //   }
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.SET_UPLOAD_DESIGN_INFO:
      if(!action.info.name)
        return state;

      return Immutable.setIn(state, ['menu', 'upload', 'designName'], action.info.name);

    case types.SET_MANUAL_CANVAS_PARAMS:
      if (typeof action.zoomLevel === 'undefined' || typeof action.fov === 'undefined' || typeof action.trans === 'undefined') {
        console.log('Required param missing for manual canvas override');
        return state;
      }

      const manualCanvas = {
        visible: action.canvasVisible,
        zoomLevel: action.zoomLevel,
        fov: action.fov,
        trans: action.trans,
      };

      return Immutable.merge(state, { canvas: manualCanvas }, { deep: true });


    case types.ZOOM:
      var zoomLevel = state.canvas.zoomLevel;
      var fov = state.canvas.fov;

      if(action.pixels) {
        // Constant log speed zoom
        const zoom_speed = 0.01;
        fov *= Math.exp(zoom_speed * action.pixels);
      } else if(action.scale) {
        // Set zoom directly
        fov *= action.scale;
      }

      // Clamp FOV between min and max vals
      fov = clampFOV(fov);

      // Quantize zoom to zoom level
      zoomLevel = quantizeZoomLevel(fov);

      var canvas = {
        zoomLevel: zoomLevel,
        fov: fov
      }

      return Immutable.merge(state, {canvas: canvas}, {deep: true});

      // var canvas = {
      //   ...state.canvas,
      //   zoomLevel: zoomLevel,
      //   fov: fov
      // }
      // return {
      //   ...state,
      //   canvas
      // };

    case types.ZOOM_IN:
      var zoomLevel = state.canvas.zoomLevel - 1;
      var fov = state.canvas.fov;

      if(zoomLevel < 0)
        zoomLevel = 0;

      fov = fovStep(zoomLevel);
      fov = clampFOV(fov);

      var canvas = {
        zoomLevel: zoomLevel,
        fov: fov
      }

      return Immutable.merge(state, {canvas: canvas}, {deep: true});

      // var canvas = {
      //   ...state.canvas,
      //   zoomLevel: zoomLevel,
      //   fov: fov
      // }
      // return {
      //   ...state,
      //   canvas
      // };

    case types.ZOOM_OUT:
      var zoomLevel = state.canvas.zoomLevel + 1;
      var fov = state.canvas.fov;

      if(zoomLevel > ZOOM_LEVELS - 1)
        zoomLevel = ZOOM_LEVELS - 1;

      fov = fovStep(zoomLevel);
      fov = clampFOV(fov);

      var canvas = {
        zoomLevel: zoomLevel,
        fov: fov
      }

      return Immutable.merge(state, {canvas: canvas}, {deep: true});

      // var canvas = {
      //   ...state.canvas,
      //   zoomLevel: zoomLevel,
      //   fov: fov
      // }
      // return {
      //   ...state,
      //   canvas
      // };

    case types.PAN_START:
      var canvas = {
        panInProgress: true,
        didDrag: false,
      }

      return Immutable.merge(state, {canvas: canvas}, {deep: true});

      // var canvas = {
      //   ...state.canvas,
      //   panInProgress: true,
      //   didDrag: false,
      // }
      // return {
      //   ...state,
      //   canvas
      // };

    case types.PAN_END:
      var canvas = {
        trans: action.trans,
        panInProgress: false,
        didDrag: false,
      }

      return Immutable.merge(state, {canvas: canvas}, {deep: true});

      // var canvas = {
      //   ...state.canvas,
      //   trans: action.trans,
      //   panInProgress: false,
      //   didDrag: false,
      // }
      // return {
      //   ...state,
      //   canvas
      // };

    case types.PINCH_START:
      var canvas = {
        panInProgress: true,
        didDrag: false,
      }

      return Immutable.merge(state, {canvas: canvas}, {deep: true});

    case types.PINCH_END:
      var canvas = {
        trans: action.trans,
        fov: clampFOV(action.fov),
        panInProgress: false,
        didDrag: false,
      }

      return Immutable.merge(state, {canvas: canvas}, {deep: true});

    case types.TOGGLE_TOOL_AREA:
      return Immutable.set(state, 'toolAreaHidden', !(state.toolAreaHidden));

      // var toolAreaHidden = !(state.toolAreaHidden);
      // return {
      //   ...state,
      //   toolAreaHidden: toolAreaHidden
      // };

    case types.SET_SELECTED_DESIGN:
      return Immutable.setIn(state, ['menu', 'design', 'selectedDesign'], action.design);

      // var menu = {
      //   ...state.menu,
      //   design: {
      //     ...state.menu.design,
      //     selectedDesign: action.design,
      //   }
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.SET_SELECTED_CONTROL:
      var newState = {
        selectedControl: action.control,
        selectedLayer: -2,
        selectedWarp: -1,
        selectedWeft: -1,
        selectedQuality: -1,
        selectedWeaveGroup: 0,
      };

      return Immutable.merge(state, newState);

    case types.SET_SELECTED_DROPDOWN:
      return Immutable.set(state, 'selectedDropdown', action.dropdown);

    case types.SET_SELECTED_QUALITY:
      return Immutable.set(state, 'selectedQuality', action.quality);

      // return {
      //   ...state,
      //   selectedQuality: action.quality
      // };

    case types.SET_SELECTED_LAYER:
      return Immutable.set(state, 'selectedLayer', action.layer);

      // return {
      //   ...state,
      //   selectedLayer: action.layer
      // };

    case types.SET_SELECTED_WARP:
      return Immutable.set(state, 'selectedWarp', action.warp);

      // return {
      //   ...state,
      //   selectedWarp: action.warp
      // };

    case types.SET_SELECTED_WEFT:
      return Immutable.set(state, 'selectedWeft', action.weft);

    case types.SET_SELECTED_WEAVE_GROUP:
      return Immutable.set(state, 'selectedWeaveGroup', action.weave_group);

    case types.SET_SELECTED_REPEATS:
      return Immutable.set(state, 'selectedRepeats', action.repeats);

    case types.SET_RANDOMIZE_MODE:
      return Immutable.set(state, 'randomizeMode', action.mode);

    case types.SET_SELECTED_3D_MODEL:
      return Immutable.set(state, 'selected3dModel', action.model);

    case types.TOGGLE_RAILROAD_3D:
      return Immutable.set(state, 'railroad3d', !(state.railroad3d));

    case types.TOGGLE_RULERS:
      return Immutable.set(state, 'showRulers', action.rulers);

      // return {
      //   ...state,
      //   selectedWeft: action.weft
      // };

    /*
     *   DB update actions
     */
    case types.DELETE_SELECTED_DESIGN:
      return Immutable.setIn(state, ['menu', 'design', 'deleteConfirmed'], (!action.confirmed) ? true : false);

      // var menu = {
      //   ...state.menu,
      //   design: {
      //     ...state.menu.design,
      //     deleteConfirmed: (!action.confirmed) ? true : false,
      //   }
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.DELETE_SELECTED_DESIGN_SUCCEEDED:
      var menu = {
        design: {
          deleteConfirmed: false,
          selectedDesign: {
            design_id: -2
          },
        }
      }

      return Immutable.merge(state, {menu: menu}, {deep: true});

      // var menu = {
      //   ...state.menu,
      //   design: {
      //     ...state.menu.design,
      //     deleteConfirmed: false,
      //     selectedDesign: {
      //       design_id: -2
      //     },
      //   }
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.DELETE_SELECTED_DESIGN_FAILED:
      var menu = {
        design: {
          deleteConfirmed: false,
          selectedDesign: {
            design_id: -2
          },
        }
      }

      return Immutable.merge(state, {menu: menu}, {deep: true});

      // var menu = {
      //   ...state.menu,
      //   design: {
      //     deleteConfirmed: false,
      //     selectedDesign: {
      //       design_id: -2
      //     },
      //   }
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.UPDATE_MAPPINGS_REQUESTED:
      if(!state.design.versions)
        return state;

      var version = state.design.versions.find(v => v.version_id === action.version_id);
      var index = state.design.versions.indexOf(version);
      var mapping = {};

      // var design = {
      //   ...state.design
      // };
      // var mapping = design.versions.find(v => v.version_id === action.version_id).mapping;

      if(action.mappings.weave_map)
        mapping.weave_map = action.mappings.weave_map;

      if(action.mappings.warp_map)
        mapping.warp_map = action.mappings.warp_map;

      if(action.mappings.weft_map)
        mapping.weft_map = action.mappings.weft_map;

      if(action.mappings.warps)
        mapping.warps = action.mappings.warps;

      if(action.mappings.wefts)
        mapping.wefts = action.mappings.wefts;

      if(action.mappings.num_repeats)
        mapping.num_repeats = action.mappings.num_repeats;

      return Immutable.setIn(state, ['design', 'versions', index], Immutable.merge(state.design.versions[index], {mapping: mapping}, {deep: true}));

      // return {
      //   ...state,
      //   design
      // };

    case types.UPDATE_MAPPINGS_SUCCEEDED:
      if(!state.design.versions)
        return state;

      var version = state.design.versions.find(v => v.mapping.mapping_id === action.data.mapping_id);
      var index = state.design.versions.indexOf(version);
      var mapping = {};

      if(action.data.weave_map)
        mapping.weave_map = action.data.weave_map;

      if(action.data.warp_map)
        mapping.warp_map = action.data.warp_map;

      if(action.data.weft_map)
        mapping.weft_map = action.data.weft_map;

      if(action.data.warps)
        mapping.warps = action.data.warps;

      if(action.data.wefts)
        mapping.wefts = action.data.wefts;

      if(action.data.num_repeats)
        mapping.num_repeats = action.data.num_repeats;

      if(action.data.valid_layers)
        mapping.valid_layers = action.data.valid_layers;

      return Immutable.setIn(state, ['design', 'versions', index], Immutable.merge(state.design.versions[index], {mapping: mapping}, {deep: true}));

      // var design = {
      //   ...state.design
      // };
      // var mapping = design.versions.find(v => v.mapping.mapping_id === action.data.mapping_id).mapping;

      // if(action.data.weave_map)
      //   mapping.weave_map = action.data.weave_map;

      // if(action.data.warp_map)
      //   mapping.warp_map = action.data.warp_map;

      // if(action.data.weft_map)
      //   mapping.weft_map = action.data.weft_map;

      // if(action.data.warps)
      //   mapping.warps = action.data.warps;

      // if(action.data.wefts)
      //   mapping.wefts = action.data.wefts;

      // if(action.data.num_repeats)
      //   mapping.num_repeats = action.data.num_repeats;

      // if(action.data.valid_layers)
      //   mapping.valid_layers = action.data.valid_layers;

      // return {
      //   ...state,
      //   design
      // };

    /*
     *   GLDesignCanvas actions
     */
    case types.RENDERER_UPDATE_STATE:
      if(state.canvas.rendererState !== action.renderer_state)
        return Immutable.setIn(state, ['canvas', 'rendererState'], action.renderer_state);
      else
        return state;

      // var canvas = {
      //   ...state.canvas,
      //   rendererState: action.renderer_state
      // }
      // return {
      //   ...state,
      //   canvas
      // };

    /*
     *   Fetch CMS content
     */
    case types.RECEIVE_QUALITY_DATA:
      var qualities = {};
      action.data.forEach(q => {
        qualities[q.quality_id] = q;
      });

      return Immutable.set(state, 'qualities', qualities);

      // return {
      //   ...state,
      //   qualities
      // };

    case types.RECEIVE_YARN_DATA:
      if (!action.data)
        return state;

      var qualities = {};
      action.data.forEach(y => {
        // Insert yarns into quality if quality exists
        if(y.quality in state.qualities) {
          if(qualities[y.quality]) {
            var quality = qualities[y.quality];
          } else {
            var quality = {};
            qualities[y.quality] = quality;
          }

          if(!quality.warpYarns)
            quality.warpYarns = []

          if(!quality.weftYarns)
            quality.weftYarns = []

          y['srgb'] = y.color;

          if(y.is_warp)
            quality.warpYarns.push(y)
          else
            quality.weftYarns.push(y)
        }
      });

      Object.entries(qualities).forEach(([key, value]) => {
        state = Immutable.setIn(state, ['qualities', key, 'warpYarns'], value.warpYarns);
        state = Immutable.setIn(state, ['qualities', key, 'weftYarns'], value.weftYarns);
      });

      return state;

      // return {
      //   ...state,
      //   qualities
      // };

    case types.RECEIVE_WEAVE_GROUP_DATA:
      if (!action.data)
        return state;

      var qualities = {};
      action.data.forEach(w => {
        // Insert weave groups into quality if quality exists
        if(w.quality in state.qualities) {
          if(qualities[w.quality]) {
            var quality = qualities[w.quality];
          } else {
            var quality = {};
            qualities[w.quality] = quality;
          }

          if(w.quality in qualities) {
            if(!quality.weaveGroups)
              quality.weaveGroups = []

            quality.weaveGroups.push(w)
          }
        }
      })

      Object.entries(qualities).forEach(([key, value]) => {
        state = Immutable.setIn(state, ['qualities', key, 'weaveGroups'], value.weaveGroups);
      });

      return state;

      // return {
      //   ...state,
      //   qualities
      // };

    case types.RECEIVE_DESIGNS_DATA:
      return Immutable.set(state, 'designs', action.data);

      // return {
      //   ...state,
      //   designs: action.data
      // };

    case types.SET_DESIGN_RECEIVE_DATA:
      return Immutable.set(state, 'design', action.data);

    case types.RECEIVE_MAPPINGS:
      if(!state.design.versions)
        return state;

      var version = state.design.versions.find(v => v.mapping.mapping_id === action.data.mapping_id);
      var index = state.design.versions.indexOf(version);
      var mapping = {};

      if(action.data.weave_map)
        mapping.weave_map = action.data.weave_map;

      if(action.data.warp_map)
        mapping.warp_map = action.data.warp_map;

      if(action.data.weft_map)
        mapping.weft_map = action.data.weft_map;

      if(action.data.warps)
        mapping.warps = action.data.warps;

      if(action.data.wefts)
        mapping.wefts = action.data.wefts;

      if(action.data.num_repeats)
        mapping.num_repeats = action.data.num_repeats;

      if(action.data.valid_layers)
        mapping.valid_layers = action.data.valid_layers;

      return Immutable.setIn(state, ['design', 'versions', index], Immutable.merge(state.design.versions[index], {mapping: mapping}, {deep: true}));

      // return {
      //   ...state,
      //   design: action.data
      // };

    case types.GET_QUALITY_DATA_ERROR:
    case types.GET_YARN_DATA_ERROR:
    case types.GET_WEAVE_GROUP_DATA_ERROR:
    case types.GET_DESIGNS_DATA_ERROR:
    case types.SET_DESIGN_ERROR:
    case types.GET_SURFACE_IMAGE_ERROR:
    case types.GET_MAPPINGS_ERROR:
      console.log('unexpected error fetching cms data:', action.error);
      return state;

    case types.RECEIVE_CLOTH_TILE:

      let url = window.URL.createObjectURL(action.data);
      window.open(url);

      return state;

    case types.GET_CLOTH_TILE_ERROR:
      console.log('unexpected error fetching renderer data:', action.error);
      return state;

    /*
     *   Image upload
     */
    case types.IMAGE_UPLOAD_REQUESTED:
      return Immutable.setIn(state, ['menu', 'status'], "WORKING");

      // var menu = {
      //   ...state.menu,
      //   status: "WORKING"
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.IMAGE_UPLOAD_SUCCEEDED:
      return Immutable.setIn(state, ['menu', 'status'], "SUCCESS");

      // var menu = {
      //   ...state.menu,
      //     status: "SUCCESS",
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.IMAGE_UPLOAD_FAILED:
      var menu = {
        status: "FAILURE",
        errors: action.data ? action.data.errors : []
      }

      return Immutable.merge(state, {menu: menu}, {deep: true});

      // var menu = {
      //   ...state.menu,
      //   status: "FAILURE",
      //   errors: action.data ? action.data.errors : []
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.SET_IMAGE_UPLOAD_ERROR:
      return Immutable.setIn(state, ['menu', 'uploadError'], action.error);

      // var menu = {
      //   ...state.menu,
      //   uploadError: action.error
      // }
      // return {
      //   ...state,
      //   menu
      // };

    /*
     *   Worker tasks
     */

    // Validate image
    case types.IMAGE_VALIDATE_REQUESTED:
      return Immutable.setIn(state, ['menu', 'status'], "WORKING");

      // var menu = {
      //   ...state.menu,
      //   status: "WORKING"
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.IMAGE_VALIDATE_SUCCEEDED:
      var menu = {
        status: "SUCCESS",
        warnings: action.data ? action.data.warnings : []
      }

      return Immutable.merge(state, {menu: menu}, {deep: true});

      // var menu = {
      //   ...state.menu,
      //   status: "SUCCESS",
      //   warnings: action.data ? action.data.warnings : []
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.IMAGE_VALIDATE_FAILED:
      var menu = {
        status: "FAILURE",
        errors: action.data ? action.data.errors : []
      }

      return Immutable.merge(state, {menu: menu}, {deep: true});

      // var menu = {
      //   ...state.menu,
      //   status: "FAILURE",
      //   errors: action.data ? action.data.errors : []
      // }
      // return {
      //   ...state,
      //   menu
      // };

    // Create design
    case types.CREATE_DESIGN_REQUESTED:
      return Immutable.setIn(state, ['menu', 'status'], "WORKING");

      // var menu = {
      //   ...state.menu,
      //   status: "WORKING"
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.CREATE_DESIGN_SUCCEEDED:
      return Immutable.setIn(state, ['menu', 'status'], "SUCCESS");

      // var menu = {
      //   ...state.menu,
      //   status: "SUCCESS",
      // }
      // return {
      //   ...state,
      //   menu
      // };

    case types.CREATE_DESIGN_FAILED:
      var menu = {
        status: "FAILURE",
        errors: action.data.errors
      }

      return Immutable.merge(state, {menu: menu}, {deep: true});

      // var menu = {
      //   ...state.menu,
      //   status: "FAILURE",
      //   errors: action.data.errors
      // }
      // return {
      //   ...state,
      //   menu
      // };

    default:
      return state;
  }
};
