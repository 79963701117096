import * as types from '../actions/actionTypes';

const config = window.weftConfig;

export default (
	state = {
		isFetching: false,
		isAdding: false,
		isAdded: false,
		cartDomain: config.FOXYCART_STORE_URL,
		itemCount: window.FC ? window.FC.json.items.length : '...'
	},
	action,
) => {
	switch (action.type) {
		case types.ADD_TO_CART_REQUEST:
			return {
				...state,
				isAdding: true
			};

		case types.ADD_TO_CART_RESPONSE:
			return {
				...state,
				iAdding: false,
				isAdded: true
			};

		case types.ADD_TO_CART_SET_LOADING:
			if(action.loading == false) {
				return {
					...state,
					isAdding: false,
					isAdded: false
				};
			} else {
				return {
					...state,
					isAdding: true,
					isAdded: false
				};
			}

		case types.ADD_TO_CART_SET_SUCCESS:
			return {
				...state,
				isAdding: false,
				isAdded: true,
			};

		case types.ADD_TO_CART_HIDE_NOTIFICATION:
			return {
				...state,
				isAdding: false,
				isAdded: false
			};

		case types.UPDATE_CART:
			var fcCount = window.FC ? window.FC.json.items.length : 0;
			return {
				...state,
				itemCount: action.itemCount ? action.itemCount : fcCount
			};

		default:
			return state;
	}
};
