import Cookies from 'js-cookie';

import * as types from '../actions/actionTypes';

const config = window.weftConfig;

export default (
  state = {
    error: {},
    isFetching: false,
    isAuthenticated: window.sessionStorage.getItem('id_token') || Cookies.get(config.jwtCookieName) ? true : false,
    is_beta: false,
    accountDropdownVisible: false,
    navbarDropdownVisible: false,
    // isAuthenticated: true,
  },
  action,
) => {
  switch (action.type) {
    case types.JWT_AUTH_SUCCEEDED:
      return {
        ...state,
        isAuthenticated: true,
        error: {}
      };

    case types.JWT_AUTH_FAILED: {

      // Parse error response
      let error = {};
      if(action.payload && action.payload.response) {
        if(action.payload.response.text.includes("TokenExpiredError")) {
          error = {
            tokenExpired: true
          };
        } else if(action.payload.response.text.includes("JsonWebTokenError")) {
          error = {
            tokenInvalid: true
          };
        }
      }

      return {
        ...state,
        isAuthenticated: false,
        error: error
      };
    }

    case types.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        error: {},
      };

    case types.LOGIN_FAILURE: {
      // Parse error response
      let errorOut = {};

      if(action.error) {
        if(typeof action.error === 'string')
          var error = JSON.parse(action.error)
        else
          error = action.error

        if(error.text && error.text.includes("Username or password is incorrect.")) {
          errorOut = {
            badCredentials: true
          };
        }
      }

      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        error: errorOut,
      };
    }

    case types.LOGOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      };

    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        error: {}
      };

    case types.SIGNUP_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      };

    case types.SIGNUP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        error: {},
      };

    case types.SIGNUP_FAILURE: {
      let errorOut = {};

      if(action.error) {
        if(typeof action.error === 'string')
          var error = JSON.parse(action.error)
        else
          error = action.error

        if(error.text.includes("PASSWORD_MISMATCH")) {
          errorOut = {
            passwordMismatch: true
          }
        }

        if(error.text.includes("PASSWORD_TOO_SHORT")) {
          errorOut = {
            passwordLength: true
          }
        }

        if(error.status === 500) {
          errorOut = {
            serverError: true
          }
        }
      }

      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        error: errorOut,
      };
    }

    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      };

    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        error: {},
      };

    case types.RESET_PASSWORD_FAILURE: {
      // Parse error response
      let errorOut = {};

      if(action.error) {
        if(typeof action.error === 'string')
          var error = JSON.parse(action.error)
        else
          error = action.error

        if(error.text && error.text.includes("No valid token exists.")) {
          errorOut = {
            tokenInvalid: true
          };
        }
      }

      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        error: errorOut,
      };
    }

    case types.GET_USER_DATA:
      return {
        ...state,
        isFetching: true,
      };

    case types.GET_USER_DATA_ERROR:
      return {
        ...state,
        isFetching: false,
      };

    case types.RECEIVE_USER_DATA:
      if (action.data && action.data.constructor === Array) {
        // List
        var user = action.data[0];
        return {
          ...state,
          ...user,
        };
      } else if (action.data.id) {
        // Detail
        var user = action.data;
        return {
          ...state,
          ...user,
        };
      };
      break;

    case types.GET_ORDERS:
      return {
        ...state,
        isFetching: true,
      };

    case types.GET_ORDERS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case types.RECEIVE_ORDERS:
      if (action.data && action.data.constructor === Array) {
        // List
        var orders = action.data;
        return {
          ...state,
          orders: orders,
        };
      } else if (action.data) {
        // Detail
        var orders = [action.data];
        return {
          ...state,
          orders: orders,
        };
      }
      break;

    case types.UPDATE_ACCOUNT_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case types.UPDATE_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case types.UPDATE_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case types.SHOW_ACCOUNT_INFO_FORM:
      return {
        ...state,
        showAccountInfoForm: true,
      };

    case types.HIDE_ACCOUNT_INFO_FORM:
      return {
        ...state,
        showAccountInfoForm: false,
      };

    case types.SHOW_USER_DROPDOWN:
      return {
        ...state,
        accountDropdownVisible: true,
      };

    case types.HIDE_USER_DROPDOWN:
      return {
        ...state,
        accountDropdownVisible: false,
      };

    case types.TOGGLE_USER_DROPDOWN:
      return {
        ...state,
        accountDropdownVisible: !state.accountDropdownVisible,
      };

    case types.SHOW_NAVBAR_DROPDOWN:
      return {
        ...state,
        navbarDropdownVisible: true,
      };

    case types.HIDE_NAVBAR_DROPDOWN:
      return {
        ...state,
        navbarDropdownVisible: false,
      };

    case types.TOGGLE_NAVBAR_DROPDOWN:
      return {
        ...state,
        navbarDropdownVisible: !state.navbarDropdownVisible,
      };

    default:
      return state;
  }
};
