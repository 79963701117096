// Polyfills
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/array';
import 'core-js/es/array/includes';
import 'core-js/es/string/includes';
import 'core-js/es/string/starts-with';
import 'core-js/es/object/entries';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';

// Google webfonts
import 'typeface-roboto';
import 'typeface-trirong';

import App from './App';

import './index.css';
import './css/main.css';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
