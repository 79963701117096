import * as types from '../actions/actionTypes';

export default (
  state = {
    isFetching: false,
  },
  action
) => {
  switch (action.type) {
    case types.SUBSCRIBE_REQUESTED:
      return {
        isFetching: true,
        status: 'PENDING',
      };

    case types.SUBSCRIBE_SUCCEEDED:
      return {
        isFetching: false,
        status: 'SUCCEEDED',
      };

    case types.SUBSCRIBE_FAILED:
      return {
        isFetching: false,
        status: 'FAILED',
        error: action.payload,
      };
      
    default:
      return state;
  }
};
