import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import user from './user';
import cart from './cart';
import shop from './shop';
import create from './create';
import collabs from './collabs';
import newsletter from './newsletter';
import system from './system';
import library from './library';
import undo from './undo';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  form: formReducer,
  create: undo(create),
  user,
  cart,
  shop,
  collabs,
  newsletter,
  system,
  library,
});

export default rootReducer;
