import * as types from './actionTypes';
import apiAction from './apiAction';

import { fetchContent } from './common';
import * as newsletterActions from './newsletterActions';

var config = window.weftConfig;


function handleErrors(response, infoText) {
  if (!response.ok) {
    return response.text().then(text => {
      console.log(`Error response in ${infoText}: ${response.status} ${response.statusText}`, text);
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        text: text
      });
    });
  } else {
    return response;
  }
}

/*
 *   Authenticate JWT
 */
export function jwtAuth() {
  return apiAction(config.webApiSwaggerDoc)(
    'JWT_AUTH',
    api => api.jwtAuth.post4,
    { }
  );
}


/*
 *   General user info
 */
function getUserData() {
  return {
    type: types.GET_USER_DATA,
    isFetching: true,
  };
}

function receiveUserData(data) {
  return {
    type: types.RECEIVE_USER_DATA,
    isFetching: false,
    data,
  };
}

function getUserDataError(error) {
  return {
    type: types.GET_USER_DATA_ERROR,
    isFetching: false,
    error
  };
}

export function fetchUserData() {
  return fetchContent('/users/', getUserData, receiveUserData, getUserDataError);
  // let usersEndpoint = `/users/`;
  // return (dispatch, getState) => {
  //   dispatch(getUserData());
  //   return fetch(apiRoot + usersEndpoint, { credentials: 'include' })
  //   .then(response => handleErrors(response, 'fetchUserData'))
  //   .then(data => data.json())
  //   .then(body => dispatch(receiveUserData(body)))
  //   .catch(err => dispatch(getUserDataError(err)));
  // };
}

/*
 *   Sign in, sign up, log out
 */
export function requestLogin(credentials) {
  return {
    type: types.LOGIN_REQUEST,
    credentials,
  };
}

function receiveLogin(user) {
  return {
    type: types.LOGIN_SUCCESS,
    id_token: user.id_token,
  };
}

function loginError(error) {
  console.log(error)
  return {
    type: types.LOGIN_FAILURE,
    error,
  };
}

export function signInUser(credentials) {
  const signInUrl = config.CMS_URL + '/auth/login/';
  // const options = {
  //   body: JSON.stringify({
  //     username: credentials.email,
  //     password: credentials.password,
  //   }),
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'X-CSRFToken': Cookies.get(config.csrfCookieName),
  //   },
  //   uri: signInUrl,
  //   withCredentials: true,
  // };

  return (dispatch, getState) => {
    dispatch(requestLogin(credentials));
    return fetch(signInUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        username: credentials.email,
        password: credentials.password,
      }),
      credentials: 'include',
    })
      .then(response => handleErrors(response, 'signInUser'))
      .then(data => data.json())
      .then(body => {
        window.sessionStorage.setItem('id_token', body.token);
        dispatch(receiveLogin(body));
      })
      .catch(err => dispatch(loginError(err)));
  };
}

function requestLogout() {
  return {
    type: types.LOGOUT_REQUEST,
  };
}

function receiveLogout() {
  return {
    type: types.LOGOUT_SUCCESS,
  };
}

function logoutError(error) {
  console.log('logoutError', error);
  return {
    type: types.LOGOUT_FAILURE,
    error,
  };
}

export function logoutUser() {
  const signOutUrl = config.CMS_URL + '/auth/logout/';

  return (dispatch, getState) => {
    dispatch(requestLogout());
    window.sessionStorage.removeItem('id_token');

    return fetch(signOutUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include',
    })
      .then(response => handleErrors(response, 'logoutUser'))
      .then(data => dispatch(receiveLogout(data)))
      .then(() => window.location.reload(), err => dispatch(logoutError(err)))
  };
}

export function requestSignup(userdata) {
  return {
    type: types.SIGNUP_REQUEST,
    userdata,
  };
}

function receiveSignup(user) {
  return {
    type: types.SIGNUP_SUCCESS,
    id_token: user.id_token,
  };
}

function signupError(error) {
  return {
    type: types.SIGNUP_FAILURE,
    error: error,
  };
}

export function signUpUser(userdata) {
  const signUpUrl = config.CMS_URL + '/auth/signup/';

  return (dispatch, getState) => {
    dispatch(requestSignup(userdata));

    if (userdata.password !== userdata.confirm_password)
      return dispatch(signupError({
        status: 400,
        text: 'PASSWORD_MISMATCH'
      }));

    if (userdata.password.length < 6)
      return dispatch(signupError({
        status: 400,
        text: 'PASSWORD_TOO_SHORT'
      }));

    return fetch(signUpUrl, {
      method: 'POST',
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        beta_code: userdata.beta_code,
        username: userdata.email,
        email: userdata.email,
        first_name: userdata.first_name,
        last_name: userdata.last_name,
        password: userdata.password,
      }),
      credentials: 'include'
    })
      .then(response => handleErrors(response, 'signUpUser'))
      .then(data => {
        dispatch(receiveSignup(data));

        // Facebook Pixel "registration completion" tracking
        if(window.fbq) {
          window.fbq('track', 'CompleteRegistration');
        }

        if(userdata.newsletter_subscribe) {
          dispatch(newsletterActions.subscribe({ email: userdata.email }));
        }
      })
      .then(() => dispatch(signInUser({
        email: userdata.email,
        password: userdata.password
      })))
      .catch(err => dispatch(signupError(err)));
  };
}

// Forgot password
export function requestForgotPassword(email) {
  return {
    type: types.FORGOT_PASSWORD_REQUEST,
    email,
  };
}

function receiveForgotPassword(email) {
  return {
    type: types.FORGOT_PASSWORD_SUCCESS,
    email,
  };
}

function forgotPasswordError(error) {
  return {
    type: types.FORGOT_PASSWORD_FAILURE,
    error,
  };
}

export function forgotPassword(formData) {
  const forgotPasswordUrl = config.CMS_URL + '/auth/forgot-password/';

  const email = formData.email;

  return (dispatch, getState) => {
    dispatch(requestForgotPassword(email));
    return fetch(forgotPasswordUrl, {
      method: 'POST',
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        email: email,
      }),
      credentials: 'include'
    })
      .then(response => handleErrors(response, 'forgotPassword'))
      .then(data => data.json())
      .then(body => {
        dispatch(receiveForgotPassword(email));
      })
      .catch(err => dispatch(forgotPasswordError(err)));
  };
}


// Reset password
export function requestResetPassword(password, token) {
  return {
    type: types.RESET_PASSWORD_REQUEST,
    password,
    token,
  };
}

function receiveResetPassword() {
  return {
    type: types.RESET_PASSWORD_SUCCESS,
  };
}

function resetPasswordError(error) {
  return {
    type: types.RESET_PASSWORD_FAILURE,
    error,
  };
}

export function resetPassword(formData, token) {
  const resetPasswordUrl = config.CMS_URL + '/auth/reset-password/';

  const password = formData.password;

  return (dispatch, getState) => {
    dispatch(requestResetPassword(password, token));
    return fetch(resetPasswordUrl, {
      method: 'POST',
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        password: password,
        token: token,
      }),
      credentials: 'include'
    })
      .then(response => handleErrors(response, 'resetPassword'))
      .then(data => data.json())
      .then(body => {
        dispatch(receiveResetPassword());
      })
      .catch(err => dispatch(resetPasswordError(err)));
  };
}


/*
 *   Account page
 */
function getOrders() {
  return {
    type: types.GET_ORDERS,
    isFetching: true,
  };
}

function receiveOrders(data) {
  return {
    type: types.RECEIVE_ORDERS,
    isFetching: false,
    data,
  };
}

function getOrdersError(error) {
  return {
    type: types.GET_ORDERS_ERROR,
    error
  };
}

export function fetchOrders() {
  return fetchContent('/orders/', getOrders, receiveOrders, getOrdersError);
  // let ordersEndpoint = `/orders/`;
  // return (dispatch, getState) => {
  //   dispatch(getOrders());
  //   return fetch(apiRoot + ordersEndpoint, { credentials: 'include' })
  //   .then(response => response.json())
  //   .then(data => dispatch(receiveOrders(data)))
  //   .catch(err => dispatch(getOrdersError(err)));
  // };
}


function requestUpdateAccountInfo() {
  return {
    type: types.UPDATE_ACCOUNT_INFO_REQUEST,
  };
}

// function receiveUpdateAccountInfo() {
//   return {
//     type: types.UPDATE_ACCOUNT_INFO_SUCCESS,
//   };
// }

// function updateAccountInfoError(error) {
//   console.log('updateAccountInfoError', error);
//   return {
//     type: types.UPDATE_ACCOUNT_INFO_ERROR,
//     error,
//   };
// }

export function editAccountInfo(data) {
  // const baseUrl = 'http://localhost:5003/api/';
  // const accountUrl = 'account';
  // const options = {
  //   body: JSON.stringify({
  //     first_name: data.firstName,
  //     last_name: data.lastName,
  //     email: data.email,
  //     password: data.password,
  //     newsletter: data.newsletter,
  //   }),
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'X-CSRFToken': Cookies.get(config.csrfCookieName),
  //   },
  //   uri: baseUrl + accountUrl,
  //   withCredentials: true,
  // };
  return (dispatch, getState) => {
    dispatch(requestUpdateAccountInfo());

    // xhr(options, (err, resp, body) => {
    //   if (err) {
    //     dispatch(updateAccountInfoError(err));
    //   }

    //   if (resp.statusCode === 200) {
    //     dispatch(receiveUpdateAccountInfo(resp));
    //   }
    // });
  };
}

export function showAccountInfoForm() {
  return {
    type: types.SHOW_ACCOUNT_INFO_FORM,
  };
}

export function hideAccountInfoForm() {
  return {
    type: types.HIDE_ACCOUNT_INFO_FORM,
  };
}

export function showUserDropdown() {
  return {
    type: types.SHOW_USER_DROPDOWN,
  };
}

export function hideUserDropdown() {
  return {
    type: types.HIDE_USER_DROPDOWN,
  };
}

export function toggleUserDropdown() {
  return {
    type: types.TOGGLE_USER_DROPDOWN,
  };
}

export function showNavbarDropdown() {
  return {
    type: types.SHOW_NAVBAR_DROPDOWN,
  };
}

export function hideNavbarDropdown() {
  return {
    type: types.HIDE_NAVBAR_DROPDOWN,
  };
}

export function toggleNavbarDropdown() {
  return {
    type: types.TOGGLE_NAVBAR_DROPDOWN,
  };
}
