import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

// Handy for debugging
// const logger = store => next => action => {
//   console.log('dispatching', action)
//   let result = next(action)
//   console.log('next state', store.getState())
//   return result
// }

// Create history for connected-react-router
export const history = createBrowserHistory();

if (typeof(window) == 'undefined'){
    global.window = new Object();
}

// export default function configureStore() {
//   const store = createStore(
//     createRootReducer(history), // root reducer with router state
//     composeWithDevTools(
//       applyMiddleware(
//         routerMiddleware(history), // for dispatching history actions
//         thunk,
//       ),
//     )
//   );
//
//   return store;
// }

export default function configureStore(initialState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer(history),
    composeEnhancers(
      /* initialState,  */

      applyMiddleware(
        thunk,
        routerMiddleware(history),
      ),
    ),
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
}
